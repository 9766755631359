import React from "react"
import Logo from "../../images/logo-beta.svg"
import {
  PanelBody,
  LogoImage,
  Title,
  Subtitle,
  Bodytext,
  CallToActionArea,
  CaSignIn,
  CaSignUp,
  InfoContainer
} from "./styles"
import MobileVideo from '../MobileVideo';

export default function LeftPanel() {
  return (
    <PanelBody>
      <LogoImage src={Logo} alt={"Conversalis Beta"} />

      <InfoContainer>
      <Title>
        Record, transcribe & analyze conversations in half of the time.
      </Title>
        <Subtitle>Get 60 min of free transcription</Subtitle>
        <Bodytext>
          {" "}
          and discover a better way to do
          <br /> conversation analysis
        </Bodytext>
        <CallToActionArea>
          <CaSignIn href="https://app.conversalis.com/login" target="_blank">sign in</CaSignIn>
          <CaSignUp href="https://app.conversalis.com/register" target="_blank">start now for free</CaSignUp>
        </CallToActionArea>
      </InfoContainer>
      <MobileVideo/>

    </PanelBody>
  )
}
