import styled from 'styled-components';
import Colors from '../../constants/Colors';
import device from '../../constants/Devices';
import bg from "../../images/background.png"

export const PanelBody = styled.div`
    background:linear-gradient(0deg, rgba(6, 0, 95, 1), rgba(6, 0, 95, 0.9)), url(${bg});
    background-blend-mode: overlay;
    background-size:cover;
    padding:0px 50px;
    display: flex;
    height: 100vh;
    flex:1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media ${device.tablet} {
        flex:initial;
        height: 50vh;
   }

   @media ${device.tablet} {
        padding:10px 50px;
   }

   @media ${device.tabletS} {
    height: 100vh;
   }

   @media ${device.mobileL} {
    display: none;
    }


`;

export const MailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: ${Colors.whiteTransparent};
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
`;

export const StorePanel = styled.div`
    display: flex;
    flex-direction: column;
    color: ${Colors.white};
    width: 100%;
    margin-bottom: 10px;
    font-weight: 300;
    align-items: flex-start;
    justify-content: center;
`;


export const StoresWrapper = styled.div`
    display: flex;
    flex-direction: row;
    color: ${Colors.grey};
    width: 100%;
    align-items: center;
    justify-content: center;

`;

export const VideoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 90%;
    height: 60%;
    
`;

export const PlayButton = styled.button `
    background: #fff;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    z-index: 9;
    border: none;
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
       opacity:0.8
    }

    &:active {
       opacity:0.6
    }
`;

export const FormInput = styled.input`
    padding: 0px 20px;
    outline: none;
    background: #fff;
    border-radius: 8px;
    border: none;
    height: 44px;
    margin-right: 5px;
    font-family: 'Inter';
    font-weight: 300;
`

export const FormButton = styled.button`
  font-size: 12px;
  text-transform: uppercase;
  color: ${Colors.white};
  font-family: "Inter", sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  place-content: center;
  background-color: ${Colors.indigo};
  border-radius: 4px;
  padding: 0px 20px;
  letter-spacing: 1px;
  height: 44px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0 0 0 12px;
  transition: 0.3s all;
  text-decoration: none;

  &:hover {
    background-color: ${Colors.magenta};
    color: ${Colors.lightGrey};
    box-shadow: "0 4px 12px rgba(18,22,25,0.16)";
  }

  @media ${device.mobileM} {
    font-size: 10px;
  }
`