export default {
  darkPurple: '#06005F',
  cyan: '#5CD4FD',
  indigo:'#716AFB',
  magenta:'#C437FB',
  lightGrey:'#F0F0F7',
  white:'#FFFFFF',
  whiteTransparent:'rgba(255, 255,255, 0.2)',
  grey:'#8585AD',
  blueGrey:'#A6A6D2'
};
