import measures from './Measures';

export default {
  mobileS: `(max-width: ${measures.mobileS})`,
  mobileM: `(max-width: ${measures.mobileM})`,
  mobileL: `(max-width: ${measures.mobileL})`,
  tabletS: `(max-width: ${measures.tabletS})`,
  tablet: `(max-width: ${measures.tablet})`,
  laptopS: `(max-width: ${measures.laptopS})`,
  laptop: `(max-width: ${measures.laptop})`,
  laptopL: `(max-width: ${measures.laptopL})`,
  desktop: `(max-width: ${measures.desktop})`,
  desktopL: `(max-width: ${measures.desktop})`,
};