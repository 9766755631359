import React, { useState, useEffect } from "react"
import {
  PanelBody,
  StorePanel,
  StoresWrapper,
  VideoWrapper,
  PlayButton,
  MailWrapper,
  FormInput,
  FormButton,
} from "../VideoPanel/styles"
import Applestore from "../../images/logo-applestore.svg"
import Playstore from "../../images/logo-playstore.svg"
import Overlay from "../../images/video-overlay.png"
import Play from "../../images/play.svg"

export default function VideoPanel() {
  const [showVideo, setShowVideo] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
   console.log(email)
  }, [email])
  
  const handleShowVideoOrImage = () => {
    setShowVideo(true);
  }

  return (
    <PanelBody>
      <VideoWrapper>
        <PlayButton
          onClick={() => handleShowVideoOrImage()}
          style={{ opacity: showVideo ? 0 : 1, zIndex: showVideo ? 0 : 10 }}
        >
          <img src={Play} alt={"Play"} style={{ width: 20 }} />
        </PlayButton>
        <img
          src={Overlay}
          alt={"Our video"}
          style={{
            width: "90%",
            height: "90%",
            position: "absolute",
            transition: "0.3s all",
            opacity: showVideo ? 0 : 1,
            zIndex: showVideo ? 0 : 8,
          }}
        />
        <iframe
          id={"ytVideo"}
          width="90%"
          height="90%"
          src="https://www.youtube.com/embed/a-1Qpsg1tHY?controls=0"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="video-desktop"
          style={{
            position: "absolute",
            borderRadius: 10,
            opacity: showVideo ? 1 : 0,
            zIndex: showVideo ? 8 : 6,
          }}
        ></iframe>
      </VideoWrapper>
      <MailWrapper>
      <StorePanel>
        <StoresWrapper>
          <img
            src={Applestore}
            alt={"Soon on Apple Store"}
            style={{ margin: "0 20px" }}
          />
          <img src={Playstore} alt={"Soon on Google Play Store"} />
        </StoresWrapper>
      </StorePanel>
        <form style={{width: "100%",display: "flex",flexDirection: "row", alignItems: "center",justifyContent: "center"}} accept-charset="UTF-8" action="https://getform.io/f/cb9eb8bb-bcd0-4db2-b790-dd240adbb2e9" method="POST">
          <FormInput type="email" name="email" placeholder="Enter your email" value={email}  onChange={(e) => setEmail(e.target.value)}/>
          <FormButton type="submit" >Join waiting list</FormButton>
        </form>
        <p style={{ fontFamily: "Inter", fontWeight: 100, margin: 10, color: "#f0f0f7", fontSize: 14 }}>Join waiting list for our Mobile app</p>
      </MailWrapper>
    </PanelBody>
  )
}
