export default {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tabletS: '740px',
    tablet: '768px',
    laptopS: '850px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  };