import React from "react"
import "normalize.css";
import LeftPanel from '../components/LeftPanel';
import VideoPanel from '../components/VideoPanel';
import SEO from '../components/seo';
import styled from 'styled-components';
import device from '../constants/Devices';
import { customerly } from "react-customerly";

export const PanelBody = styled.div`
   display:flex; 
   flex-direction:row; 
   margin: 0;
   overflow-Y: hidden;

   @media ${device.tablet} {
    flex-direction:column; 
   }

   @media ${device.tabletS} {
    flex-direction:row; 
   }

   @media ${device.mobileL} {
    flex-direction:column; 
   }

`;

if (typeof window !== 'undefined') {
  customerly.initialize("dab0a734", {
    widget_position: "left",
    widget_color: "716AFB",
  });
}

export default function Home() {
  return (
  <PanelBody>
    <SEO/>
    <LeftPanel/>
    <VideoPanel/>
  </PanelBody>
  )
}
