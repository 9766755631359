import styled from "styled-components"
import Colors from "../../constants/Colors"
import device from "../../constants/Devices"

export const PanelBody = styled.div`
  background: ${Colors.lightGrey};
  padding: 0px 50px;
  display: flex;
  height: 100vh;
  min-width: 400px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  @media ${device.laptopS} {
    min-width: auto;
    padding: 10px 10px 10px 10px;
  }

  @media ${device.tablet} {
    height: 50vh;
    padding: 10px 50px 10px 50px;
  }

  @media ${device.tabletS} {
    height: 100vh;
    padding: 10px;
   }


  @media ${device.mobileL} {
    justify-content: flex-start;
    padding: 15px 15px;
    height: 100vh;
    overflow-y: hidden;
    min-width: initial;
    align-items: stretch;
    overflow: scroll;
    padding-bottom: 200px;
  }
`

export const InfoContainer = styled.div`
    margin-top: 180px;

    @media ${device.laptopS} {
      margin-top: 20px;
  }

    @media ${device.tablet} {
    margin-top: 50px;
  }

    @media ${device.mobileL} {
        margin-top: 20px;

    }
`

export const LogoImage = styled.img`
  margin-top:60px;
  max-width: 60%;

  @media ${device.laptopS} {
      margin-top: 20px;
  }

  @media ${device.tablet} {
    margin-top: 50px;
  }

  @media ${device.mobileL} {
    margin-top:30px;
    align-self: flex-start !important;
  }

  @media ${device.mobileM} {
    width: 80%;
  }
`

export const Title = styled.h1`
  font-size: 35px;
  color: ${Colors.darkPurple};
  font-family: "Inter", sans-serif;
  font-weight: 600;
  width: 400px;

  @media ${device.laptopS} {
      margin-top: 20px;
      width: 300px;
      font-size: 25px;
  }

  @media ${device.tablet} {
    margin: 10px 0;
  }

  @media ${device.mobileL} {
    font-size: 21px;
    width: 250px;
    margin: 20px 0;
  }
`

export const Subtitle = styled.p`
  font-size: 14px;
  margin: 0;
  color: ${Colors.indigo};
  font-family: "Inter", sans-serif;
  font-weight: 600;

  @media ${device.mobileL} {
    margin: 25px 0;
  }
`

export const Bodytext = styled.p`
  font-size: 14px;
  margin: 0;
  color: ${Colors.grey};
  font-family: "Inter", sans-serif;
  font-weight: 300;

  @media ${device.mobileL} {
  }
`

export const CallToActionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;

  @media ${device.mobileL} {
    margin: 20px 0;
  }
`

export const CaSignIn = styled.a`
  font-size: 12px;
  height: 44px;
  text-transform: uppercase;
  color: ${Colors.indigo};
  font-family: "Inter", sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  place-content: center;
  background-color: ${Colors.white};
  border-radius: 4px;
  padding: 0px 20px;
  letter-spacing: 1px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 10px 0;
  transition: 0.3s all;
  text-decoration: none;

  &:hover {
    background-color: ${Colors.grey};
    color: ${Colors.lightGrey};
    box-shadow: "0 4px 12px rgba(18,22,25,0.16)";
  }

  @media ${device.mobileL} {
    flex: 1;
    text-align: center;
  }

  @media ${device.mobileM} {
    font-size: 10px;
  }
`

export const CaSignUp = styled.a`
  font-size: 12px;
  text-transform: uppercase;
  color: ${Colors.white};
  font-family: "Inter", sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  place-content: center;
  background-color: ${Colors.indigo};
  border-radius: 4px;
  padding: 0px 20px;
  letter-spacing: 1px;
  height: 44px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0 0 0 12px;
  transition: 0.3s all;
  text-decoration: none;

  &:hover {
    background-color: ${Colors.magenta};
    color: ${Colors.lightGrey};
    box-shadow: "0 4px 12px rgba(18,22,25,0.16)";
  }

  @media ${device.mobileM} {
    font-size: 10px;
  }
`
