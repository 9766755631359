import React, { useState, useRef } from "react"
import { MobileVideoWrapper, StorePanel, StoresWrapper, PlayButton, VideoWrapper, MailWrapper, FormInput, FormButton } from "./styles"
import Applestore from "../../images/logo-applestore-grey.svg"
import Playstore from "../../images/logo-playstore-grey.svg"
import Overlay from "../../images/video-overlay.png"
import Play from "../../images/play.svg"

export default function MobileVideo() {

  const [showVideo, setShowVideo] = useState(false);
  const [email, setEmail] = useState("");
  const ytVideo = useRef();

  const handleShowVideoOrImage = () => {
    setShowVideo(true);
  }

  return (
    <div>
      <MobileVideoWrapper>
<VideoWrapper>
<PlayButton
          onClick={() => handleShowVideoOrImage()}
          style={{ opacity: showVideo ? 0 : 1, zIndex: showVideo ? 0 : 10 }}
        >
          <img src={Play} alt={"Play"} style={{ width: 20 }} />
        </PlayButton>
           
        <img
          src={Overlay}
          alt={"Our video"}
          style={{
            width: "100%",
            height: "80%",
            position: "absolute",
            transition: "0.3s all",
            opacity: showVideo ? 0 : 1,
            zIndex: showVideo ? 0 : 8,
          }}
        />
          <iframe
              width="90%"
              height="90%"
            src="https://www.youtube.com/embed/a-1Qpsg1tHY?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="video-mobile"
            ref={ytVideo}
            style={{
              position: "absolute",
              borderRadius: 10,
              opacity: showVideo ? 1 : 0,
              zIndex: showVideo ? 8 : 6,
            }}
          ></iframe>
        </VideoWrapper>

       
       
      </MobileVideoWrapper>
      <MailWrapper>
        <p style={{ fontFamily: "Inter", fontWeight: 100, margin:10, fontSize: 14, color:"#8585AD" }}>Join waiting list for our Mobile app</p>
      <StorePanel>
        <StoresWrapper>
          <img
            src={Applestore}
            alt={"Soon on Apple Store"}
          />
          <img src={Playstore} alt={"Soon on Google Play Store"} />
        </StoresWrapper>
      </StorePanel>
        <form style={{width: "100%",display: "flex",flexDirection: "row", alignItems: "center",justifyContent: "center"}} accept-charset="UTF-8" action="https://getform.io/f/cb9eb8bb-bcd0-4db2-b790-dd240adbb2e9" method="POST">
          <FormInput type="email" name="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <FormButton type="submit">Join Beta</FormButton>
        </form>
      </MailWrapper>
    </div>
  )
}
