import styled from 'styled-components';
import Colors from '../../constants/Colors';
import device from '../../constants/Devices';


export const MobileVideoWrapper = styled.div`
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 300px;

    @media ${device.mobileL} {
        display: flex;
    
  }   
`
export const StorePanel = styled.div`
    display: flex;
    flex-direction: row;
    color: ${Colors.grey};
    width: 100%;
    align-items: flex-start;
    justify-content: space-around;

`;


export const StoresWrapper = styled.div`
    display: flex;
    flex-direction: row;
    color: ${Colors.grey};
    width: 100%;
    align-items: center;
    padding: 10px;
    justify-content: space-around;

`;

export const VideoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%; 
`;

export const PlayButton = styled.button `
    background: #fff;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    z-index: 9;
    border: none;
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
       opacity:0.8
    }

    &:active {
       opacity:0.6
    }
`;

export const MailWrapper = styled.div`
    display: none;
    flex-direction: column;
    background: ${Colors.whiteTransparent};
    padding: 10px 20px;
    border-radius: 10px;
    align-items: center;
    width: 90%;
    justify-content: center;
    border: 1px solid ${Colors.cyan};

    @media ${device.mobileL} {
        display: flex;
    
  }   
`;

export const FormInput = styled.input`
    padding: 0px 10px;
    outline: none;
    background: #fff;
    border-radius: 4px 0px 0px 4px;
    border: none;
    height: 44px;
    font-family: 'Inter';
    font-weight: 300;
    width: 60%;
`

export const FormButton = styled.button`
  font-size: 12px;
  text-transform: uppercase;
  color: ${Colors.white};
  font-family: "Inter", sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  place-content: center;
  background-color: ${Colors.darkPurple};
  border-radius: 0 4px 4px 0;
  padding: 0px 10px;
  letter-spacing: 1px;
  height: 44px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s all;
  text-decoration: none;
  flex: 1;

  &:hover {
    background-color: ${Colors.magenta};
    color: ${Colors.lightGrey};
    box-shadow: "0 4px 12px rgba(18,22,25,0.16)";
  }

  @media ${device.mobileM} {
    font-size: 10px;
  }
`
